var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('dashboard-core-app-bar',{model:{value:(_vm.expandOnHover),callback:function ($$v) {_vm.expandOnHover=$$v},expression:"expandOnHover"}}),_c('dashboard-core-drawer',{attrs:{"expand-on-hover":_vm.expandOnHover},on:{"update:expandOnHover":function($event){_vm.expandOnHover=$event},"update:expand-on-hover":function($event){_vm.expandOnHover=$event}}}),_c('dashboard-core-view'),(_vm.arrtext.lemgth < 2)?_c('base-material-snackbar',_vm._b({attrs:{"type":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},'base-material-snackbar',{
      timeout: _vm.snackbarTimeout,
      top: true,
      right: true
    },false),[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]):_c('base-material-snackbar',_vm._b({attrs:{"type":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},'base-material-snackbar',{
      timeout: _vm.snackbarTimeout,
      top: true,
      right: true
    },false),_vm._l((_vm.arrtext),function(msg,ind){return _c('div',{key:ind},[_vm._v(_vm._s(msg))])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }